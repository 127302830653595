import React from 'react';
import logo from '../sections/welcome/assets/logo192.png';

const Header = () => {
  return (
    <header className="container mx-auto px-5 h-auto flex justify-between text-white 
    py-2 fixed top-0 w-full z-20 transition-all 
    duration-700">
        <a href="/">
        <img 
                className="object-contain h-[6rem] w-[10rem]" 
                src={logo}
                alt="Stackpro Solutions"
                width="100%"
                height="100%"
            />
        </a>
        <nav className="flex items-center">
            <a href="" className="ml-6 text-right text-lg uppercase a-underlined-text">Home</a>
            <a href="" className="ml-6 text-right text-lg uppercase a-underlined-text">Portfolio</a>
            <a href="" className="ml-6 text-right text-lg uppercase a-underlined-text">About Us</a>
            <a href="" className="ml-6 text-right text-lg uppercase">Contact Us</a>
        </nav>
    </header>

  );
}

export default Header;
