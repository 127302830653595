import Header from './views/layouts/header'
import Showcase from './views/sections/welcome/showcase'
import About from './views/sections/welcome/about'
import Services from './views/sections/welcome/services'
import Projects from './views/sections/welcome/projects'
import ContactUs from './views/sections/welcome/contact'
function App() {
  return (
    <div className="App">
      <Header/>
      <Showcase/>
      <About/>
      <Services/>
      <Projects/>
      <ContactUs/>
    </div>
  );
}

export default App;
