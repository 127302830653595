import React from 'react';

const ContactUs = () => {
  return (
    <div >
     <div style={{ 
  margin: '10px', 
  ///backgroundColor: '#f2f2f2', 
  //border: '2px solid black', 
  padding: '30px', 
  borderRadius: '10px', 
  textAlign: 'center',
  height: '400px', // Adjusted height to accommodate the additional content
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}}>
  <h2 style={{ margin: '0.5rem auto', fontSize: '40px' }}>Let's talk about the project</h2>
  <p>  <button style={{ fontSize: '1rem', padding: '10px', backgroundColor: '#007BFF', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
    Contact Us
  </button></p>
  </div>


  <div className="align-center" style={{ display: 'flex', justifyContent: 'center', padding: '70px', borderRadius: '10px' ,  backgroundColor: '#f2f2f2' }}>
  <div style={{ width: '40%',  border: '2px solid #ccc', padding: '20px', borderRadius: '5px' }}>
    <h2 style={{ margin: '0.5rem auto', fontSize: '40px' }}>Get in touch.</h2>
    <p>We're here to help and answer any questions you might have. We will answer your inquiries in a maximum of 48 hours</p>
    <br/>
    <p>hradmin@stackprosolutions.com</p>
    <p>+63 095 5649 1739</p>
    <p>stackprosolutions.com</p>
    <p>Corner Velez & Luna Streets, Cagayan de Oro City, Philippines 9000</p>



  </div>
 
<div style={{ width: '60%', marginLeft: 'auto', margin: '50px',  }}>
    <form action="/submit_form.php" method="post">
        <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
            <label htmlFor="firstName" style={{ marginRight: '10px', flex: '1' }}>First Name:</label>
            <input type="text" id="firstName" name="firstName" required style={{ flex: '2', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }} />

            <label htmlFor="lastName" style={{ marginLeft: '20px', marginRight: '10px', flex: '1' }}>Last Name:</label>
            <input type="text" id="lastName" name="lastName" required style={{ flex: '2', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }} />
        </div>

        <div style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
            <label htmlFor="email" style={{ marginRight: '10px', flex: '1' }}>Email:</label>
            <input type="email" id="email" name="email" required style={{ flex: '2', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }} />

            <label htmlFor="contactNumber" style={{ marginLeft: '20px', marginRight: '10px', flex: '1' }}>Contact #:</label>
            <input type="text" id="contactNumber" name="contactNumber" required style={{ flex: '2', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }} />
        </div>

        <div style={{ marginBottom: '15px', display: 'flex'}}>
            <label htmlFor="message" style={{ marginRight: '10px', flex: '1' }}>Message:</label>
            <textarea id="message" name="message"  required style={{ flex: '4.5', padding: '50px', borderRadius: '5px', border: '1px solid #ccc' }}></textarea>
        </div>

        <input type="submit" value="Submit" style={{ backgroundColor: '#007BFF', color: '#fff', padding: '10px', borderRadius: '5px', cursor: 'pointer', border: 'none' }} />
    </form>
</div>


</div>

      
    </div>
  );
};

export default ContactUs;
