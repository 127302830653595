import React from 'react';

const Showcase = () => {
    return (
        <div id="showcase" className="s-showcase">
        <img src="https://elements-video-cover-images-0.imgix.net/files/32a57979-dbac-40bc-b382-4f72cfbf6fbe/inline_image_preview.jpg?auto=compress&h=800&w=1200&fit=crop&crop=edges&fm=jpeg&s=8a9447b3d6eb683bc6c3235185038370"
         className="w-full object-cover max-h-screen h-[100vh]" id="videoElement" preload="metadata" alt='showcase'/>
           
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="container">
            <div className="absolute inset-0 flex flex-col items-center 
        justify-center text-white p-5 mt-14">
                <div className="text-center text-[5.7rem]">
                    <h1>We Transform Concepts<br/> into Reality</h1>
                </div>
                <p className="text-[1.3rem]/[1.4rem] whitespace-pre-line mt-4 text-center px-[1rem]">
                As a leading IT solutions company, our expertise lies <br/>
                in crafting exceptional web applications and mobile app solutions tailored to your needs.
                </p>
            </div>
        </div>
    </div>
    
    );
  }
  
  export default Showcase;
  