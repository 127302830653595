import React from 'react';

const About = () => {
    return (
    <div id="about" className="s-about">
        <div className="container mx-auto px-5 h-auto flex w-full max-h-full h-[60vh]">
            <div className="grid grid-cols-2 p-4 space-y-16">
                <div className="rounded-lg w-full min-h-[14rem] mt-6">
                    <img src="https://img.freepik.com/free-photo/medium-shot-woman-working-laptop_23-2150323516.jpg" className="rounded-lg"/>
                </div>
                <div className="mr-10">
                    <div className="text-[50px] leading-[1.2]">
                        <h1 className="">Why Us?</h1>
                    </div>
                    <p className="text-base text-justify leading-9 text-slate-950">
                        Welcome to StackPro Solutions, your esteemed partner for sophisticated and bespoke IT 
                        solutions tailored to optimize your business processes with a focus on cost-effectiveness, 
                        security, and efficiency. Our dedicated expertise lies in delivering customized business 
                        process solutions that integrate cutting-edge technology, ensuring a secure and efficient 
                        foundation for your operations. At StackPro, we prioritize innovation and cost-effectiveness, 
                        recognizing the importance of delivering value to your organization. Choose StackPro for a 
                        professional and strategic approach to your digital transformation, where our solutions are 
                        meticulously crafted to align seamlessly with your unique business objectives. 
                    </p>
                </div>
            </div>
        </div>
    </div>
    
    );
  }
  
  export default About;
  